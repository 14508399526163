@import '../../../media-queries.scss';
@import 'src/pages/mint-home/_variables.scss';

.bottom-navbar * {
  pointer-events: auto;
}

.bottom-navbar {
  pointer-events: none;
  position: fixed;
  // todo charlie
  bottom: 0; // calc(-env(safe-area-inset-top) - env(safe-area-inset-bottom));

  width: 100%;
  height: $bottom-navvar-height;
  z-index: 3;

  max-width: $app-max-width;
  left: calc(50% - $app-max-width / 2);
  @media (max-width: $app-max-width) {
    left: 0;
  }

  // max-width: 414px;
  // left: calc(50% - 207px);
  // @media (max-width: $app-max-width) {
  //   left: 0;
  // }

  // background-color: rgba(0, 0, 0, 0.2);
  // -webkit-backdrop-filter: blur(33px);
  // backdrop-filter: blur(33px);

  background-color: black; //  pink;
  // margin: 5px;

  // padding: 10px; // 10px 25px 10px;

  // padding: 10px 0 20px 0;

  // padding: 3px 12px 0 12px;

  display: flex;
  flex-direction: row;
  gap: 14%;
  align-items: center;
  justify-content: center;

  .navbar-items {
    width: 100%;
    max-width: 600px;

    display: flex;
    flex-direction: row;
    gap: 13%;
    align-items: center;
    justify-content: center;

    // padding: 10px;

    margin: 4px 16px 0 16px;

    .navbar-item {
      font-size: 10px;
      font-weight: 600;
      line-height: 13px;
      text-decoration: none;
      text-align: center;
      color: white;

      display: flex;
      flex-direction: column;
      gap: 4px;

      .navbar-item-icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 25px;
        height: 25px;
        // width: 20px;
        // height: 20px;
        margin: auto;
      }
      .navbar-item-label {
        height: fit-content;
        user-select: none;
      }
    }
  }
}

// @media (max-width: $app-max-width) {
//   .bottom-navbar {
//     // padding: 10px 10px 25px 10px;

//     .navbar-items {
//       .navbar-item {
//         .navbar-item-icon {
//           width: 20px;
//           height: 20px;
//         }
//       }
//     }
//   }
// }
