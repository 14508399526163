body {
  margin: 0;
  background-color: #000 !important;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  // background-image: linear-gradient(225.49deg, #231f45 19.84%, rgba(22, 16, 63, 0.5) 47.64%);
  // background-image: linear-gradient(135deg, #6347f6 0%, #2b30f5 100%);
  // background-image: linear-gradient(135deg, #add7ff 10%, #ffc3eb 25%, #fbffc8 40%, #fbffc8 50%, #add7ff 60%, #ffc3eb 75%, #fbffc8 90%);
  // background: radial-gradient(52.27% 24.31% at -2.27% 78.32%, #702dff 0%, rgba(122, 60, 255, 0) 100%), rgba(96, 58, 255, 0.23);
  // background-image: linear-gradient(135deg, #add7ff 10%, #ffc3eb 25%, #fbffc8 40%, #fbffc8 50%, #add7ff 60%, #ffc3eb 75%, #fbffc8 90%);
  // background-color: black;

  overflow: hidden;
  // overflow-y: auto;

  height: 100%; // 100svh; // calc(100svh + 1px);
  background-color: yellow;
}

// body::-webkit-scrollbar {
//   display: none;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
  display: flex;
  flex-flow: column;
  min-height: calc(100svh + 1px);
  overflow-x: hidden;
  animation: root-enter 0.4s ease-in-out 0.2s both;

  max-width: 100%;
  margin: 0 auto;
  // background-color: #111018;
  background-color: black;

  // height: 100%;
  position: absolute;
  top: 0;
  height: 100%; // calc(100svh); //  + $bottom-navvar-height); // calc(100svh + 1px);

  // min-height: calc(100svh + env(safe-area-inset-top));
  // min-height: calc(100svh + 1px);

  background-color: black; //  rgba(255, 255, 0, 0.5);
}

// html.full-height {
//   min-height: 100%;
//   height: 100%;
//   position: relative;
//   overflow: hidden;
//   body {
//     min-height: 100%;
//     height: 100%;
//     position: relative;
//   }
//   #root {
//     min-height: 100%;
//   }
// }

@keyframes root-enter {
  0% {
    filter: brightness(0) blur(30px);
  }

  100% {
    filter: brightness(1) blur(0);
  }
}

button {
  border: 0;
  cursor: pointer;
}

button.svs-btn {
  border: 0;
  cursor: pointer;
  transition: 0.4s;
  border-radius: 10px;
}

.svs-btn {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 50px;
  padding: 0 20px;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 0 0 2.5px #fff;
  transition-property: background-color;
}

.svs-big-btn {
  @extend .svs-btn;
  width: 317px;
  height: 72px;
  line-height: 72px;
}

:is(.svs-btn, .svs-big-btn) {
  color: #fff;
  background: #000b;

  .fill {
    fill: #fff;
  }

  .stroke {
    stroke: #fff;
  }
}

:is(.svs-btn, .svs-big-btn):hover {
  color: #000;
  background: #fff;

  .fill {
    fill: #000;
  }

  .stroke {
    stroke: #000;
  }
}

:is(.svs-btn, .svs-big-btn).disabled,
:is(.svs-btn, .svs-big-btn):disabled {
  color: #000;
  background: #888;
  cursor: default;
  border-color: #888;
}

:is(.svs-btn, .svs-big-btn).success {
  background: green;
}

:is(.svs-btn, .svs-big-btn).danger {
  background: red;
}

@keyframes svs-shimmer-bg-moving {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.svs-shimmer-bg {
  background-image: linear-gradient(135deg, #add7ff 10%, #ffc3eb 25%, #fbffc8 40%, #fbffc8 50%, #add7ff 60%, #ffc3eb 75%, #fbffc8 90%);
  background-size: 300%;
  animation: shimmer-background-moving 10s linear infinite;
  background-position: 0% 0%;
}

.modal_f12l36jo {
  display: none;
}

.tp-ready .modal_f12l36jo {
  display: initial;
}

w3m-modal {
  z-index: 100;
  position: relative;
}
