@import '../../../media-queries.scss';
@import 'src/pages/mint-home/_variables.scss';

// ===================================================

.item-info {
  pointer-events: none;
  position: fixed;
  z-index: 1;

  width: 100%;
  height: calc(100% - $bottom-navvar-height); // account for bottom navbar
  left: 0;
  top: 0;

  max-width: $app-max-width;
  left: calc(50% - $app-max-width / 2);
  @media (max-width: $app-max-width) {
    left: 0;
  }

  transition: flex 0.3s ease-out;

  // background-color: rgba(0, 0, 0, 0.2);
  // -webkit-backdrop-filter: blur(33px);
  // backdrop-filter: blur(33px);
  // padding: 10px 20px 20px 20px;

  background: none;
  padding: 20px;

  font-family: 'Inter', sans-serif;
  font-size: 25px;
  color: white;
  text-align: left;

  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

  .item-info-bottom {
    position: absolute;
    bottom: 0;
    height: fit-content;
    width: calc(100% - 40px);

    &.enabled {
      animation: slide-in 0.35s ease-out forwards;
    }

    &.disabled {
      animation: slide-out 0.35s ease-out forwards;
      // @media (max-width: 699px) {
      //   animation: slide-out 0.35s ease-out forwards;
      // }
    }

    .author {
      pointer-events: auto;
      font-size: 15px;
      font-weight: 900;
      line-height: 2;
      text-decoration: none; // underline;
      width: 70%;
    }

    .description {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: white;

      text-align: left;

      margin-bottom: 16px;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      width: calc(100% - 80px);
      // height: 18px; // safari needs this to render just one line...

      height: fit-content;
      text-overflow: ellipsis;

      // necessary
      p {
        margin: 0;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .line-mid-screen {
    position: absolute;
    top: 50%;
    background-color: red;
    width: calc(100% - 40px);
    height: 1px;
  }

  .sidebar {
    pointer-events: auto;
    position: absolute;
    right: 10px;
    top: calc(50% - 78px);
    width: 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .sidebar-item {
      background: none;
      font-size: 10px;
      font-weight: 800;
      line-height: 13px;
      text-decoration: none;
      text-align: center;
      color: white;

      display: flex;
      flex-direction: column;

      .sidebar-item-icon {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        width: 40px;
        height: 40px;
        margin: auto;
      }

      .sidebar-item-label {
        user-select: none;
        height: fit-content;
        margin-top: 3px;
        width: 100%;
      }

      &.author {
        .sidebar-item-icon {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        }
        .sidebar-item-label {
          margin-top: 8px;
        }
      }
      &.gem {
        .sidebar-item-icon {
          width: 50px;
          height: 50px;
        }
      }
      &.share {
        .sidebar-item-icon {
          width: 45px;
          height: 45px;
        }
      }

      &.debug {
        pointer-events: none;
        width: 300px;
        margin-right: 250px;
        font-family: 'Inter', sans-serif;
        font-size: 13px;
        // font-weight: 300;
        line-height: 20px;
        color: red;
        text-align: right;
      }
    }
  }
}

@media (max-width: 599px) {
  .item-info {
    padding: 16px;

    .item-info-bottom {
      .description {
        margin-bottom: 12px;
      }
    }

    .sidebar {
      top: calc(50% - 67px);
      right: 0;

      .sidebar-item {
        &.author {
          .sidebar-item-icon {
            width: 40px;
            height: 40px;
          }
        }
        &.gem {
          .sidebar-item-icon {
            width: 40px;
            height: 40px;
          }
        }
        &.share {
          .sidebar-item-icon {
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(-100px);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateX(-100px);
    opacity: 0;
    visibility: hidden;
  }
}
