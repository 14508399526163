@import '../../media-queries.scss';
@import 'src/pages/mint-home/_variables.scss';

// body {
//   overflow-y: hidden !important;
// }

// disable scroll completely while we are in the intro carousel
.intro {
  overflow-y: hidden;
}

// body #root {
//   overflow-y: hidden;
// }

// #root {
//   overflow-y: hidden;
// }

.story-game {
  // background: rgba(255, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  z-index: 0;
  display: 'block';
  visibility: hidden;

  // background-color: rgba(255, 0, 0, 0.5);
  // border: 5px solid #fff;

  &.disabled {
    // -webkit-animation: fadeinout 4s linear forwards;
    // animation: fadeout 0.35s ease-out forwards;
    visibility: hidden;
  }

  &.enabled {
    // This is a workaround to prevent iframe jitter/flash of old viewer, caused by chrome's graphics caching
    // animation: fadeinhack 0.35s linear forwards; // todo charlie
    visibility: visible;
  }
}

@keyframes fadeinhack {
  0% {
    opacity: 0;
    visibility: visible;
  }
  49% {
    opacity: 0;
    visibility: visible;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

// .main-con {
//   margin: 0 auto;
//   width: 100%;
//   width: $app-max-width;

//   // height: calc(100svh - $bottom-navvar-height);
//   // padding-top: env(safe-area-inset-top);

//   // background-color: orange;

//   @media screen and (max-width: $app-max-width) {
//     width: 100%;
//   }
// }

.transitioning-section {
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;

  &.fixed {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.home-base {
  position: absolute;
  width: 100%;
  height: 100%;
}

// ===================================================
// Button Blue

.button-blue {
  background: #4598fa;

  &:hover {
    color: white;
  }
  &:disabled {
    opacity: 0.5;
    color: white;
    cursor: default;

    &:hover {
      color: white;
    }
  }

  // width: 100%;
  margin: 0 auto;
  border-radius: 60px; // 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px; // 14px;
  line-height: 17px;
  padding: 10px 20px;
  color: white;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: none;
}

// ===================================================
// Button Border

.button-border {
  background: transparent; //  #4598fa;
  border: 2px solid white;

  &:hover {
    color: white;
  }
  &:disabled {
    opacity: 0.5;
    color: white;
    cursor: default;

    &:hover {
      color: white;
    }
  }

  width: 190px;
  margin: 0 9px; // auto;
  border-radius: 60px; // 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px; // 14px;
  line-height: 17px;
  padding: 10px 20px;
  color: white;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: none;
}

.carousel .control-arrow {
  display: none;
}
