@import 'src/pages/mint-home/_variables.scss';

.ai-form {
  background-color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  height: calc(100svh - $bottom-navvar-height);
  padding-top: env(safe-area-inset-top);

  z-index: 9999;

  .title {
    font-size: 28px;
    font-weight: 600;
    color: white;
    padding-bottom: 20px;
  }

  .inputG {
    ::placeholder {
      font-size: 2vh;
    }

    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 12px;

    p {
      color: white;
      margin-bottom: 8px;
    }

    input {
      box-sizing: border-box;
      border: 0.2vh solid grey;
      border-radius: 0.8vh;
      font-size: 2.3vh;
      padding: 10px;
      color: black;
      touch-action: none;

      width: 90%;
      height: 45px;
    }
  }

  .advanced-options {
    font-size: 2.3vh;
    color: darkgray;
    cursor: pointer;
    width: 90%;
    text-align: center;
    padding-top: 12px;
  }

  .create-button {
    width: 90%;
    height: 45px;
    padding: 2%;
    background-color: #6359ec;
    color: white;
    font-size: 2.3vh;
    border: none;
    border-radius: 0.8vh;
    cursor: pointer;
    transition: background-color 0.3s ease;

    margin-top: 6px;
  }

  .create-button:hover {
    background-color: #5248d4;
  }
}
